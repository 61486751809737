.answer-box, .answer-box-off {
    /* flex-grow: 1; */
    background-color: #ededed;
    min-height: 500px;
    min-width: 200px;
    height: 70vh;
    width: 20%;
    border: 4px solid #7461c2;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    /* overflow-y: scroll; */
}

.answer-box-off {
    pointer-events: none;
    opacity:0.7;
}

.answer-feed {
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;

}

.answer-input-form {
    width: 95%;
    height: 10%;
    align-self: center;
    margin-bottom: 1%;
    border: 4px solid #7461c2;
    border-radius: 5px;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.answer-attempt-container-a, .answer-attempt-container-b {
    height: auto;
    padding-left: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    word-wrap:break-word;

    display: flex;
    flex-direction: row;
    align-content: flex-start;
}

.answer-attempt-container-a {
    background-color: white;
}

.answer-attempt-container-b {
    background-color: b;
}


.answer-attempt-username {
    font-weight: bold
}

.answer-attempt-answer {
    flex-grow: 1;
    padding-left: 5%;
}

.answer-input {
    line-height: 30px;
    height: 100%;
    font-size: 1.25rem;
    border-radius: 5px;
    border-style: none;
    padding-left: 4%;
}

.dd-image-container, .dd-container {
	display: flex;
	width: 60%;
    min-width: 700px;
    min-height: 500px;
    height: 70vh;
    flex-direction: column;
    border: 4px solid white;
    padding: 1%;

    align-items: center;
}

.dd-image-container {
    background-image: url('/dailydouble.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

}

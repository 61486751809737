.game-summary-content {
    flex-grow: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    /* min-width: 1200px; */
}

.winner-container {
    display: block;
    text-align: center;
    width: 18%;
    margin-top: 5%;
    color: white;

    font-size: 1.5rem;
}

.runners-up-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 70%;
    margin-bottom: 5%;
    color: white;
    overflow-x: scroll;
    max-height: 225px;
    /* align-items: center; */
    justify-content: center;
    /* align-items: center; */
    overflow-x: scroll;
    background-color: rgb(255, 255, 255, 0.2)
}

.runner-up-info-container {
    display: flex;
    /* flex-grow: 0; */
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 20%;
    color: white;
    margin-left: 3%;
    margin-right: 3%;
    font-size: 1.25rem;
    /* margin-top: 2%; */
}

@font-face { font-family: "univers-75"; src: url('/univers-75-black_bigfontsite.com.ttf'); }

.runner-up-info-box {
    display: flex;
    width: 100%;
    text-align: center;
    background-color: black;
    width: 100%;
    height: 80%;
    padding-right: 1%;
    padding-left: 1%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.runner-up-info-score {
    width: 95%;
    background-color: #020685;
    min-height: 20%;
    margin: 0 auto;
    margin-top: 3%;
    align-self: center;
    font-family: "univers-75";
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-shadow: 2px 2px 1px black;
}

.runner-up-info-image {
    margin-top: 3%;
    /* max-height: 70%; */
    /* max-width: 90%; */
    height: 70%;
    max-width: 95%;
    margin-bottom: 2.5%;
}

.winner-info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    width: 100%;
    min-height: 200px;
}

.winner-info-score {
    width: 95%;
    background-color: #020685;
    flex-grow: 1;
    margin-bottom: 2.5%;
    margin-top: 2.5%;
    font-family: "univers-75";
    font-size: 1.5rem;
    text-shadow: 2px 2px 1px black;

    display: flex;
    justify-content: center;
    flex-direction: column;
}

.winner-info-image {
    max-width: 95%;
    margin-bottom: 2.5%;
}


.waiting-room-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* flex-basis: 100%; */
    width: 80%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5vw;
    margin-top: 5vw;
}

.waiting-room-boxes-container {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    width: 80%;
    align-items: center;
    justify-content: center;
}

.waiting-room-settings-container, .waiting-room-players-container {
    display: block;
    width: 40%;
    flex-grow: 1;
    margin-left: 5%;
    margin-right: 5%;
    color: white;
}

.waiting-room-settings-box, .waiting-room-settings-box-off {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    flex-grow: 1;
    align-items: center;
    border-radius: 10px;
    color: black;
    min-height: 200px;

    justify-content: center;
}

.waiting-room-settings-box-off{
    pointer-events: none;
    opacity: 0.8;
}

 .waiting-room-players-box {
     display: flex;
     flex-grow: 1;
     flex-direction: row;
     /* max-width: 200px; */
     flex-wrap: wrap;
     align-items: flex-start;
     justify-content: center;
     border-radius: 10px;
     padding: 2%;
     min-height: 150px;
     max-height: 200px;
     overflow-y: scroll;
     background-color:  rgba(255, 255, 255, 0.3);
     /* background-blend-mode: lighten; */
     border: 5px solid white;
 }

.invite-friends-container {
    display: block;
    width: 70%;

    /* flex-grow: 1; */
    margin-left: 5%;
    margin-right: 5%;
    color: white;
}

.invite-friends-link-box {
    background-color: #f7f7f7;
    color: #48b5b1;
    min-height: 20px;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
}

.invite-friends-link-box:hover{
    color: black;
    font-weight: normal;
}

.select-difficulty-container {
    width: 80%;
    margin-bottom: 10%;
}

.select-difficulty {
    width: 100%;
    margin-top: 2%;
}

.start-game-button {
    text-decoration: none;
    border-radius: 5px;
    cursor:pointer;
    color:white;
    font-weight: bolder;
    width: 80%;
    font-size: 14px;
    background-color: #4CAF50;

    flex-grow: 0.2;
}

.player-info-container {
    display:block;
    width: 40%;
    background-color: black;
    padding: 1%;
    /* flex-grow: 1; */
    margin: 1%;
    border-radius: 5px;
}

.player-info-name {
    background-color: blue;
    margin-bottom: 1%;
    height: auto;
    text-align: center;
    color: white;
    font-family: Helvetica;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 1.25rem;
    background-color: #020685;
    padding-top: 5%;
    padding-bottom: 5%;
}

.player-info-image {
    max-width:100%;
    height: auto;
    margin-top: 2%;
}

.divTable{
	display: flex;
	width: 60%;
    min-width: 700px;
    min-height: 500px;
    height: 70vh;
    flex-direction: column;
    border: 4px solid #7461c2;
    padding: 1%;
    background-color: black;
}

.divTableRow {
	display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
}

@font-face { font-family: "swiss-911"; src: url('/swiss-911-extra-compr9c22f.ttf'); }


.divTableHeaderCell {
    flex: 1;
    background-color: #020685;
    text-align: center;
    margin: 0.5%;
    justify-content: center;
    color: white;
    font-family: Helvetica;
    font-weight: bolder;
    text-shadow: 2px 2px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
	word-wrap:break-word;
	flex-wrap: wrap;
	/* flex-basis: auto; */
}

.divTableCategoryRow {
	display: flex;
    flex-direction: row;
    justify-content: space-between;

    flex: 1;
}

.divTableQuestionsContainer, .divTableQuestionsContainer-off {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    height: 75%;

    justify-content: space-between;
}

.divTableQuestionsContainer-off {
    pointer-events: none;
    opacity: 0.7;
}

.divTableQuestionsColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0.5%;

    justify-content: space-between;
}

.divTableQuestionCell:hover {
    font-size: 55px;
    cursor: pointer;
}

.divTableQuestionCell, .divTableQuestionCell-off{
    background-color: #020685;
    text-align: center;
    vertical-align: middle;
    font-family: "swiss-911";
    font-size: 50px;
    color:#edc15a;
    text-shadow: 4px 4px black;

    height: 18%;
    margin-top: 0.5%;
    margin-bottom: 0.5%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.divTableQuestionCell-off {
	pointer-events: none;
}

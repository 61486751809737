@font-face { font-family: "itc-korinna"; src: url('/Korinna-Regular.otf'); }
.question-container, .answer-container{
	display: flex;
	width: 60%;
    min-width: 700px;
    min-height: 500px;
    height: 70vh;
    flex-direction: column;
    border: 4px solid white;
    background-color: #0a1582;
    padding: 1%;

    align-items: center;
}

.question-container {
	justify-content: center;
}
.answer-container {
	justify-content: space-between;
}

.question-text{
	margin-left: 10%;
    margin-right: 10%;

    text-align: center;

    font-family: "itc-korinna";
    font-size: 2.5em;
    color:#f7f9f7;
    font-weight: 700;

    text-shadow: 2px 2px 2px black;
}

.chosen-category-container {
	display: block;
	text-align: center;
}

.category-text {
	font-family: Helvetica;
	font-size: 2.5em;
	font-weight: 700;
	color: white;
	margin-bottom: 5%;
	text-shadow: 4px 4px 1px black;
}

.value-text, .answer-value-text, .answer-value-text-green, .answer-value-text-red  {
	font-size: 2.5em;
	font-weight: bold;
	color: #deb666;
	text-shadow: 2px 2px 1px black;
}

.answer-value-text {
	font-size: 2em;
}

.answer-value-text-green {
	font-size: 2em;
	color: #298f34;
}

.answer-value-text-red {
	font-size: 2em;
	color: #d04a43;
}

.answerer-and-value-container {
	display: block;
	margin-top: 8%;
	text-align: center;
	color:white;
}

.answer-text-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 30%;
	color: white;

	align-items: center;
}

.answerer-text {
	font-size: 2em;
	font-weight: 700;
	color:white;
	text-shadow: 2px 2px black;
	margin-bottom: 1%;
	text-align: center;
}

.answer-text {
	font-size: 2.5em;
	font-family: "itc-korinna";
	font-weight: 700;
	color:white;
	text-shadow: 3px 3px 1px black;
	text-align: center;
}

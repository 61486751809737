.scoreboard {
    /* flex-grow: 1; */
    background-color: rgb(2, 6, 133, 0.7);
    min-height: 500px;
    min-width: 200px;
    height: 70vh;
    width: 15%;
    border:  4px solid #cea65b;
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.scoreboard-element-container {
    display: block;
    width: 100%;
    background-color: black;
    margin-top: 2.5%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-bottom: 5%;
}

.scoreboard-element-score, .scoreboard-element-negative-score {
    width: 90%;
    background-color: #020685;
    color: white;
    text-align: center;
    height: 25%;
    font-family: "univers-75";
    font-size: 1.5rem;

    text-shadow: 2px 2px 1px black;

    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
}

.scoreboard-element-negative-score {
    color: #e33b3b;
}

@font-face { font-family: "univers-75"; src: url('/univers-75-black_bigfontsite.com.ttf'); }

.scoreboard-element-canvas-image {
    max-width: 100%;
    display: inline-block;
    margin-top: 3%;
    max-height: 70%;

    /* background-color: #020685;
    color: white;
    margin: 0 auto;
    text-align: center; */
}

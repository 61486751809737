.game-room-container {
  background-image: url('/game_background_2.jpg');
  min-height: 100vh;
  width: 100%;
  /* min-width: 650px; */
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
}

.game-room-content {
    flex-grow: 1;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    /* min-width: 1200px; */
}

.corner-logo {
    width: 50%;
    padding-top: 3vh;
    padding-left: 5vh;
    color: white;
    align-self: flex-start;
}

.game-info-bar {
    width: 95%;
    align-self: center;

    flex-grow: 1;
    border: 4px solid #cea65b;
    background-color:#020685;

    background-image: linear-gradient(to bottom right, #020685 , #3c0643);
    margin-top: 4vh;
    height: 6vh;
    min-height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* justify-content: center; */
}

.picking-category {
    font-size: 1.75rem;
    font-family: Helvetica;
    font-weight: bold;
    text-shadow: 2px 2px 2px black;
    color: white;

    margin: 0 auto;
}

.showing-question {
    font-size: 1.75rem;
    font-family: Helvetica;
    font-weight: bold;
    text-shadow: 2px 2px 2px black;
    color: white;

    margin: 0 auto;
}

.timer, .timer-hidden{
    color: #cea65b;
    font-family: Helvetica;
    margin-right: 5%;
    font-weight: bold;
    font-size: 2em;
    text-shadow: 2px 2px 1px black;
}

.timer-hidden {
    opacity: 0;
}

.round {
    color: white;
    font-family: Helvetica;
    margin-left: 3%;
    font-weight: bold;
    font-size: 1.5em;
    text-shadow: 2px 2px 1px black;
}

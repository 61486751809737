.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex-grow: 1;
}

.join-create-container {
  background-color: #f7f7f7;
  flex-grow: 1;
  margin-top: 10vh;
  margin-bottom: 5vh;
  min-height: 150px;
  border-radius: 10px;
  min-width: 300px;

  display: flex;
  flex-direction: column;
  /* padding-left: 5%;
  padding-right: 5%; */
  width: 25%;
  padding-top: 2%;
  padding-bottom: 2%;
  justify-content: space-between;
}

.landing-container {
  background-image: url('/game_background_2.jpg');
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.copyright-notice {
    font-size: 8px;
    color: white;
    margin-bottom: 2vh;
    white-space: pre-wrap;
    text-align: center;
}


.name-input {
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    line-height: max(3vh, 40px);
    font-size: 1em;
    text-decoration: none;
    padding-left: 10px;
    box-shadow: 1px 1px #e6e6e6;
    width: 60%;
    align-self: center;
}

.sigCanvas{
    align-self: center;
    width: 70%;
    margin-top: 2%;
    margin-bottom: 2%;
    min-height: 150px;
    border: 6px solid black;
}

.join-create-button-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    align-items: center;
    align-self: center;
    width: 70%;
}


.join-button, .create-button {
    text-decoration: none;
    height: max(3vh, 45px);
    margin-top: 2%;
    margin-bottom: 2%;
    border-radius: 10px;
    cursor:pointer;
    color:white;
    font-weight: bolder;
    /* flex-grow: 1; */
    width: 100%;
    font-size: 14px;
}

.join-button {
    background-color: #329ce3
}
.create-button {
    background-color: #4CAF50
}

.landing-content {
    flex-grow: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header-logo {
    width: 100%;
    text-align: center;
    padding-top: 5vh;
    display:flex;
    flex-direction: column;
    color: white;

    -webkit-filter: drop-shadow(10px 10px 10px #222);
    filter: drop-shadow(10px 10px 10px #222);
}

.mobile-background {
    height: 90vh;
    background-image:  url('/game_background_2.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    padding: 5vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
